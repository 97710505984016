export const SAMPLE = "SAMPLE";
export const SAVE_STUDENT_DATA = "SAVE_STUDENT_DATA";
export const SAVE_PG_INFO = "SAVE_PG_INFO";
export const SAVE_PUTME_INFO = "SAVE_PUTME_INFO";
export const SAVE_DIRECT_ENTRY_INFO = "SAVE_DIRECT_ENTRY_INFO";
export const SAVE_TRANSFER_INFO = "SAVE_TRANSFER_INFO";
export const SAVE_JUPEB_INFO = "SAVE_JUPEB_INFO";
export const PART_TIME = "PART_TIME";
export const SAVE_MENU_ITEMS = "SAVE_MENU_ITEMS";
export const JUPEP_APPLICATIONS = "JUPEP_APPLICATIONS";
export const CSPG_APPLICATIONS = "CSND_APPLICATIONS";
export const FEES_ASSIGNMENT = "FEES_ASSIGNMENT";
export const SAVE_IMPERSONATION_ITEMS = "SAVE_IMPERSONATION_ITEMS";
