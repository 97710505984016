import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "UserManagement";

export const getUserAccountUrl = () => `${baseUrl}`;
export const getAllUsersUrl = (filter) =>
	`${baseUrl}/all-users?${generateUrlParams(filter)}`;
	
export const getUsersUrl = (filter) =>
	`${baseUrl}?${generateUrlParams(filter)}`;

export const createUserUrl = () => `${baseUrl}/create-user`;

export const getInvoiceStudentsUrl = (filter) =>
	`${baseUrl}/user-records?${generateUrlParams(filter)}`;

export const editUserUrl = (id) => `${baseUrl}/update-user?userId=${id}`;

export const toggleUserStatusUrl = (id) =>
	`${baseUrl}/toggle-user-status?userId=${id}`;
