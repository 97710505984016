import { ReactComponent as Icon } from "../../assets/svgs/fileHolderIcon.svg";
import { Button } from "../button/Button";
import "./style.css";

export const FileHolder = ({ name, onClick }) => {
	return (
		<div className="smc_file_holder border-bottom px-4 d-flex align-items-center justify-content-between">
			<div className="d-flex align-items-center gap-2">
				<Icon />
				<p className="smc_file_holder_name">{name}</p>
			</div>
			<Button label="Print" onClick={onClick} />
		</div>
	);
};
