import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Payment";

export const getMyInvoicesUrl = (paymentType) =>
	`${baseUrl}/student-invoices/${paymentType}`;
export const initiateSchoolFeesPaymentUrl = ({
	sessionId,
	paymentTypeId,
	levelId
}) =>
	`${baseUrl}/schoolfees/initiate-payment?sessionId=${sessionId}&paymentTypeId=${paymentTypeId}&levelId=${levelId}`;
export const initiateAcceptanceFeePaymentUrl = () =>
	`${baseUrl}/acceptance/initiate-payment`;
export const initiateSundryFeePaymentUrl = (filter) =>
	`${baseUrl}/sundry/initiate-payment?${generateUrlParams(filter)}`;
export const generateFeesInvoiceUrl = () => `${baseUrl}/generate-fee-Invoice`;
export const generateSchoolFeesBalanceInvoiceUrl = () =>
	`${baseUrl}/generate-balance-invoice`;
export const getInvoiceUrl = (rrr) => `${baseUrl}/invoice/${rrr}`;
export const getFeeRecieptUrl = ({
	sessionId,
	levelId,
	paymentTypeId,
	paymentPurposeId
}) =>
	`${baseUrl}/fee-receipt?${generateUrlParams({
		sessionId,
		levelId,
		paymentTypeId,
		paymentPurposeId
	})}`;
export const getSchoolFeesRecieptUrl = ({
	sessionId,
	yearofStudyId,
	schoolFeesPaymentType
}) =>
	`${baseUrl}/schoolfeesReceipt?sessionId=${sessionId}&yearofStudyId=${yearofStudyId}&schoolFeesPaymentType=${schoolFeesPaymentType}`;
export const getAllStudetInvoicesUrl = (matricNo) =>
	`${baseUrl}/all-student-invoices?userId=${matricNo}`;
export const getSchoolFeesAssignmentsUrl = (filter) =>
	`${baseUrl}/school-fee-assignment?${generateUrlParams(filter)}`;
export const getSundryFeesAssignmentsUrl = (filter) =>
	`${baseUrl}/sundry-fee-assignment?${generateUrlParams(filter)}`;
export const updateSchoolFeesAssignmentsUrl = (schoolFeeAssignmentId) =>
	`${baseUrl}/update-school-fee-assignment/${schoolFeeAssignmentId}`;
export const updateSundryFeesAssignmentsUrl = (sundryFeeAssignmentId) =>
	`${baseUrl}/update-sundry-fee-assignment/${sundryFeeAssignmentId}`;

export const bulkSundryFeesAssignmentsUrl = () =>
	`${baseUrl}/bulk-sundry-fee-assignment`;
export const uploadFeesUrl = () =>
	`${baseUrl}/school-fee-assignment-bulk-upload`;
export const getAcceptanceFeesUrl = (filter) =>
	`${baseUrl}/acceptance-fee-assignment?${generateUrlParams(filter)}`;
export const updateAcceptanceFeesUrl = (acceptanceFeeAssignmentId) =>
	`${baseUrl}/update-acceptance-fee-assignment/${acceptanceFeeAssignmentId}`;
export const deactivatefeeinvoiceUrl = () =>
	`${baseUrl}/deactivate-fee-invoice`;
export const updateFeeInvoiceUrl = () => `${baseUrl}/update-fee-invoice`;
export const getPGFeeAmountUrl = (filter) =>
	`${baseUrl}/pg-fee-amount?${generateUrlParams(filter)}`;
export const generatePGSchoolFeesInvoiceUrl = () =>
	`${baseUrl}/pg-school-fees/generate-invoice`;
export const bulkUpdateSchoolFeesAssignmentUrl = () =>
	`${baseUrl}/bulk-update-school-fee-assignment`;
export const downloadFeesSampleUrl = () =>
	`${baseUrl}/bulk-fee-assignment-upload-sample-sheet`;
export const getPGFeeAssignmentsUrl = (filter) =>
	`${baseUrl}/pg-fee-assignment?${generateUrlParams(filter)}`;
export const updatePGFeesUrl = () => `${baseUrl}/pg-fee-assignment`;
export const getSchoolFeesAssignmentBreakdownUrl = (id) =>
	`${baseUrl}/school-fee-assignment-breakdown/${id}`;
export const updateSchoolFeesAssignmentBreakdownUrl = (id) =>
	`${baseUrl}/update-school-fee-assignment/${id}`;
export const getScholarshipsUrl = (filter) =>
	`${baseUrl}/scholarships?${generateUrlParams(filter)}`;
export const updateScholarshipUrl = (id) =>
	`${baseUrl}/update-scholarship/${id}`;
export const deleteScholarshipUrl = (id) => `${baseUrl}/scholarship/${id}`;
export const toggleScholarshipStatusUrl = (id) =>
	`${baseUrl}/deactivate-scholarship/${id}`;
export const getStudentsScholarshipsUrl = (filter) =>
	`${baseUrl}/scholarship-students?${generateUrlParams(filter)}`;
export const getStudentScholarshipsUrl = (filter) =>
	`${baseUrl}/student-scholarships?${generateUrlParams(filter)}`;
export const awardScholarshipUrl = () => `${baseUrl}/award-scholarship`;
export const deleteStudentSessionScholarshipUrl = (id) =>
	`${baseUrl}/student-scholarship-session?studentScholarshipId=${id}`;
export const deleteStudentScholarshipUrl = (filter) =>
	`${baseUrl}/student-scholarship?${generateUrlParams(filter)}`;
export const downloadFeesReportUrl = (filter) =>
	`${baseUrl}/download-fee-report?${generateUrlParams(filter)}`;
export const getPGFeesReportUrl = (filter) =>
	`${baseUrl}/pg-fee-report?${generateUrlParams(filter)}`;
export const getFeesReportUrl = (filter) =>
	`${baseUrl}/fee-report?${generateUrlParams(filter)}`;
export const downloadPGFeesReportUrl = (filter) =>
	`${baseUrl}/download-pg-fee-report?${generateUrlParams(filter)}`;
export const getstudentFullFeeReportUrl = (filter) =>
	`${baseUrl}/student-fee-report?${generateUrlParams(filter)}`;
export const downloadsSudentFullFeeReportUrl = (filter) =>
	`${baseUrl}/download-full-fee-report?${generateUrlParams(filter)}`;

export const getPaymentFeeDiscount = (filter) =>
	`${baseUrl}/get-fee-discount?${generateUrlParams(filter)}`;
export const updateFeeDiscount = (filter) =>
	`${baseUrl}/update-fee-discount?userId=${filter}`;

export const toggleFeeDiscount = (id) =>
	`${baseUrl}/toggle-fee-discount?feeDiscountId=${id}`;

export const getBookShopReportUrl = (filter) =>
	`${baseUrl}/bookshop-report?${generateUrlParams(filter)}`;

export const downloadsBookShopReportUrl = (filter) =>
	`${baseUrl}/download-bookshop-report?${generateUrlParams(filter)}`;

export const cloneFeesAssignmentUrl = () =>
	`${baseUrl}/clone-fee-assignment-from-session`;

export const getScholarshipsReportUrl = (filter) => 
	`${baseUrl}/scholarship-report?${generateUrlParams(filter)}`;

export const downloadScholarshipReportUrl = (filter) => 
	`${baseUrl}/download-scholarship-report?${generateUrlParams(filter)}`;