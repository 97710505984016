import { INITIAL_DATE } from "../../utils/constants";
import { formatDateFromAPI } from "../../utils/formatDate";
import { SAVE_TRANSFER_INFO } from "../constant";

export const transferInitialState = (data) => ({
	nameRegNoConfirmation: {},
	StudentTypeId: data?.studentTypeId,
	applicationTypeId: data?.applicationTypeId,
	passport: {
		passport: data?.passport
	},
	programmeInfo: {
		regNo: data?.regNumber,
		utmeScore: data?.programmeInfoResponse?.utmeScore,
		schoolProgramme: data?.programmeInfoResponse?.schoolProgramme,
		schoolProgrammeId: data?.programmeInfoResponse?.schoolProgrammeId,
		utmeResultSlip: data?.programmeInfoResponse?.resultSlip,
		...(data?.programmeInfoResponse?.firstSubjectId && {
			firstSubjectId: {
				label: data?.programmeInfoResponse?.firstSubject,
				value: data?.programmeInfoResponse?.firstSubjectId
			}
		}),
		...(data?.programmeInfoResponse?.secondSubjectId && {
			secondSubjectId: {
				label: data?.programmeInfoResponse?.secondSubject,
				value: data?.programmeInfoResponse?.secondSubjectId
			}
		}),
		...(data?.programmeInfoResponse?.thirdSubjectId && {
			thirdSubjectId: {
				label: data?.programmeInfoResponse?.thirdSubject,
				value: data?.programmeInfoResponse?.thirdSubjectId
			}
		}),
		...(data?.programmeInfoResponse?.fourthSubjectId && {
			fourthSubjectId: {
				label: data?.programmeInfoResponse?.fourthSubject,
				value: data?.programmeInfoResponse?.fourthSubjectId
			}
		}),
		...(data?.programmeInfoResponse?.facultyId && {
			facultyId: {
				label: data?.programmeInfoResponse?.faculty,
				value: data?.programmeInfoResponse?.facultyId
			}
		}),
		departmentId: {
			label: data?.programmeInfoResponse?.department,
			value: data?.programmeInfoResponse?.departmentId
		},
		...(data?.programmeInfoResponse?.departmentOptionId && {
			departmentOptionId: {
				label: data?.programmeInfoResponse?.departmentOption,
				value: data?.programmeInfoResponse?.departmentOptionId
			}
		}),
		rrr: data?.programmeInfoResponse?.rrr
	},
	personalInfo: {
		firstName: data?.personalInfoResponse?.firstname,
		middleName: data?.personalInfoResponse?.middlename,
		surName: data?.personalInfoResponse?.lastname,
		...(data?.personalInfoResponse?.dateOfBirth && {
			dateOfBirth:
				data?.personalInfoResponse?.dateOfBirth === INITIAL_DATE
					? ""
					: formatDateFromAPI(data?.personalInfoResponse?.dateOfBirth)
		}),
		...(data?.personalInfoResponse?.gender && {
			sex: {
				label: data?.personalInfoResponse?.gender,
				value: data?.personalInfoResponse?.genderId
			}
		}),
		mobileNo: data?.personalInfoResponse?.mobileNumber,
		email: data?.personalInfoResponse?.email,
		disability: data?.personalInfoResponse?.disability,
		hasDisability: data?.personalInfoResponse?.disability ? "Yes" : "No",
		...(data?.personalInfoResponse?.country && {
			country: {
				label: data?.personalInfoResponse?.country,
				value: data?.personalInfoResponse?.countryId
			}
		}),
		...(data?.personalInfoResponse?.state && {
			state: {
				label: data?.personalInfoResponse?.state,
				value: data?.personalInfoResponse?.stateId
			}
		}),
		...(data?.personalInfoResponse?.lga && {
			lga: {
				label: data?.personalInfoResponse?.lga,
				value: data?.personalInfoResponse?.lgaId
			}
		}),
		homeTown: data?.personalInfoResponse?.homeTown,
		contactAddress: data?.personalInfoResponse?.contactAddress,
		sponsorFullName: data?.personalInfoResponse?.sponsorFullName,
		sponsorAddress: data?.personalInfoResponse?.sponsorContactAddress,
		sponsorMobileNo: data?.personalInfoResponse?.sponsorMobileNumber,
		...(data?.personalInfoResponse?.sponsorRelationship && {
			sponsorRelationship: {
				label: data?.personalInfoResponse?.sponsorRelationship,
				value: data?.personalInfoResponse?.sponsorRelationshipId
			}
		}),
		rrr: data?.personalInfoResponse?.rrr
	},
	educationHistoryInfo: {
		schoolName: data?.qualificationDetailResponse?.schoolName,
		department: data?.qualificationDetailResponse?.majorField,
		yearFrom: formatDateFromAPI(
			data?.qualificationDetailResponse?.yearFrom || ""
		),
		yearTo: formatDateFromAPI(
			data?.qualificationDetailResponse?.yearTo || ""
		),
		certificate: data?.qualificationDetailResponse?.certificate
	},
	oLevelResult: {
		sittings: data?.olevelResponse?.map((item) => ({
			...item,
			resultPin: item?.resultPin,
			resultPinSno: item?.resultSerialNumber,
			examNumber: item.examNumber,
			examCentre: item.examCenter,
			oLevelType: {
				value: item.examinationTypeId,
				label: item.examinationType
			},
			examYear: { value: item.examYear, label: item.examYear },
			subjects: [
				...Object?.keys(item?.subjectGrade).map((key, index) => ({
					subject: {
						label: key?.toUpperCase(),
						value: Object?.keys(item?.subjectGradeId)?.[index]
					},
					grade: {
						label: item?.subjectGrade?.[key],
						value: Object?.values(item?.subjectGradeId)?.[index]
					}
				}))
			]
		}))
	},
	referral: {
		...(data?.referralDetailResponse?.refererCategory && {
			refererCategory: {
				value: data?.referralDetailResponse?.refererCategory,
				label: data?.referralDetailResponse?.refererCategory
			}
		}),
		referer: data?.referralDetailResponse?.referer,
		refererMobileNumber: data?.referralDetailResponse?.refererMobileNumber
	}
});

export const transferReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_TRANSFER_INFO:
			return Object.assign({}, state, {
				...action.payload
			});
		default:
			return state;
	}
};
