import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "AdmissionList";

//global admission list search
export const getSearchAdmissionList = (filter) =>
	`${baseUrl}/search-admission-list?${generateUrlParams(filter)}`;
//an object containing all the params and their values is passed to the generateUrlParams function
export const getAdmissionList = (filter) =>
	`${baseUrl}/admission-list?${generateUrlParams(filter)}`;

export const getClearanceInfoUrl = (filter) =>
	`${baseUrl}/admission-clearance-list?${generateUrlParams(filter)}`;
export const updateClearanceStatusUrl = ({ admissionListId }) =>
	`${baseUrl}/update-admission-clearance-status/${admissionListId}`;

export const downloadAdmissionsListTemplateUrl = () =>
	`${baseUrl}/download-sample-sheet`;
export const bulkUploadAdmissionListUrl = () =>
	`${baseUrl}/upload-admission-list`;
export const singleUploadAdmissionListUrl = () =>
	`${baseUrl}/upload-admission-record`;
export const updateAdmissionListRecordUrl = (admissionListId) =>
	`${baseUrl}/update-admission-list-record/${admissionListId}`;
export const deleteAdmissionListRecordUrl = (admissionListId) =>
	`${baseUrl}/admission-list-record/${admissionListId}`;
export const deleteAdmissionListUrl = () => `${baseUrl}/admission-list-record`;
export const getGraduationListUrl = (filter) =>
	`${baseUrl}/graduation-list?${generateUrlParams(filter)}`;
export const bulkUploadGraduationListUrl = () =>
	`${baseUrl}/upload-graduation-list`;
export const uploadGraduationRecordUrl = () => `${baseUrl}/graduate-student`;
export const deleteStudentOnGraduationListUrl = ({ userId }) =>
	`${baseUrl}/delete-graduation-record?userId=${userId}`;
export const deleteGraduationListRecordsUrl = ({
	departmentId,
	graduationYearId
}) =>
	`${baseUrl}/delete-graduation-list?departmentId=${departmentId}&graduationYearId=${graduationYearId}`;
export const getAdmissionListRecordUrl = (regNumber) =>
	`${baseUrl}/admission-record?regNumber=${regNumber}`;
export const getAdmissionListReportsUrl = (filter) =>
	`${baseUrl}/admission-list-report?${generateUrlParams(filter)}`;
export const downloadAdmissionListReportsUrl = (filter) =>
	`${baseUrl}/download-admission-list-report?${generateUrlParams(filter)}`;
export const getAllApplicantsUrl = (filter) =>
	`${baseUrl}/all-applications?${generateUrlParams(filter)}`;
export const changeApplicationStatusUrl = () => `${baseUrl}/admit-applicant`;
