import { useState, useEffect } from "react";
import img from "../../assets/svgs/menu-btn.svg";
import "./buttonDropdown.css";
import { Spinner } from "../spinner/spinner";

export const ButtonDropdown = ({
	buttonGroup,
	isLoading = false,
	disabled = false
}) => {
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		// handles closing of the drop down
		const handleCloseAction = () => {
			if (toggle) {
				setToggle(false);
			}
		};
		window.addEventListener("click", handleCloseAction);
		return () => window.removeEventListener("click", handleCloseAction);
	}, [toggle]);

	return (
		<div className="btn_drop_down_container">
			<button
				onClick={() => setToggle(!toggle)}
				className="btn_dropdown"
				disabled={isLoading || disabled}
			>
				{isLoading ? <Spinner /> : <img src={img} alt="" />}
			</button>

			{toggle && (
				<div className="drop_down_menu">
					{buttonGroup?.map((btn, i) => (
						<p
							key={i}
							onClick={btn?.onClick}
							className={btn?.style ? btn?.style : ""}
						>
							{btn?.name}
						</p>
					))}
				</div>
			)}
		</div>
	);
};
