import { MainRouter, UnathorisedRouter } from "../routers/index";
import { lazy } from "react";

const LazyDefault = lazy(() =>
	import("../../pages/applicant/Application/pages/Default/default")
);
const LazyGeneratePaymentInvoice = lazy(() =>
	import(
		"../../pages/applicant/Application/pages/JambStudents/pages/GenerateJambPaymentInvoice/generateJambPaymentInvoice"
	)
);
const LazyViewPaymentInvoice = lazy(() =>
	import(
		"../../pages/applicant/Application/pages/JambStudents/pages/Invoice/jambStudentsInvoice"
	)
);
const LazyUnauthorized = lazy(() =>
	import("../../pages/shared/Unauthorized/unauthorized")
);
const LazyPutmeApplication = lazy(() =>
	import("../../pages/shared/PUTMEApplication/putmeApplication")
);
const LazyPutmeApplicationDetails = lazy(() =>
	import(
		"../../pages/shared/PUTMEApplication/PutmeApplicationDetails/putmeApplicationDetails"
	)
);
const LazyTransferApplication = lazy(() =>
	import("../../pages/shared/TransferApplication/transferApplication")
);
const LazyTransferApplicationDetails = lazy(() =>
	import(
		"../../pages/shared/TransferApplication/TransferApplicationDetails/transferApplicationDetails"
	)
);
const LazyDirectEntryApplication = lazy(() =>
	import("../../pages/shared/DirectEntryApplication/directEntryApplication")
);
const LazyDirectEntryApplicationDetails = lazy(() =>
	import(
		"../../pages/shared/DirectEntryApplication/DirectEntryApplicationDetails/directEntryApplicationDetails"
	)
);

export const applicantRoutes = [
	{
		path: "/application",
		component: LazyDefault,
		exact: true,
		router: MainRouter,
		title: "Application"
	},
	{
		path: "/application/generate_invoice",
		component: LazyGeneratePaymentInvoice,
		exact: true,
		router: MainRouter,
		title: "Application"
	},
	{
		path: "/application/view_invoice",
		component: LazyViewPaymentInvoice,
		exact: true,
		router: MainRouter,
		title: "Application"
	},
	{
		path: "/application/degree_application",
		router: MainRouter,
		component: LazyPutmeApplication,
		exact: true
	},
	{
		path: "/application/degree_application_details",
		router: MainRouter,
		component: LazyPutmeApplicationDetails,
		exact: true
	},
	{
		path: "/application/transfer_application",
		router: MainRouter,
		component: LazyTransferApplication,
		exact: true
	},
	{
		path: "/application/transfer_application_details",
		router: MainRouter,
		component: LazyTransferApplicationDetails,
		exact: true
	},
	{
		path: "/application/direct_entry_application",
		router: MainRouter,
		component: LazyDirectEntryApplication,
		exact: true
	},
	{
		path: "/application/direct_entry_application_details",
		router: MainRouter,
		component: LazyDirectEntryApplicationDetails,
		exact: true
	},
	{
		path: "/no_access",
		component: LazyUnauthorized,
		exact: true,
		router: UnathorisedRouter,
		title: "Unauthorized access"
	}
];
