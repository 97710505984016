import React, { useState, createRef, createContext } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import useAuthAction from "../../custom-hooks/useAuthAction";
import Logout from "../../pages/shared/Logout/logout";
import {
	TOKEN_HOLDER,
	USER_NAME_HOLDER,
	USER_ROLE_HOLDER
} from "../../utils/constants";
import { Banner, GlobalMenu, PageLoader, SideMenu } from "../index";
import "./layout.css";
import { useApiGet } from "../../api/apiCall";
import { getStudentProfileUrl } from "../../api/urls";

export const parent = createRef(null);

export const StudentProfileContext = createContext();

let menuItems = [];

const studentPaths = {
	dashboard: "/dashboard",
	profile: "/profile",
	acceptance: "/acceptance",
	"course registration": "/course_registration",
	"school fees": "/school_fees",
	results: "/results",
	sundry: "/sundry",
	application: "/application",
	referral: "/referral",
	hostel: "/hostel"
};

const applicantPaths = {
	application: "/application"
};

const staffPaths = {
	dashboard: "/dashboard",
	profile: "/profile",
	applications: "/applications/setup",
	referral: "/referral",
	"approve courses": "/approve_courses",
	"course management": "/course_management/manage_course",
	"fees assignment": "/fees_assignment/school_fees",
	"invoice management": "/invoice_management/invoice",
	"lecturer management": "/lecturer_management/lecturer_upload",
	"notice board setup": "/notice_board_management",
	"results & class list": "/records",
	reports: "/reports/application_payment_reports",
	"result management": "/results/classlist",
	"student management": "/student_management/search_student",
	"user management": "/user_management/users",
	"hostel management": "/hostel_management/manage_hostel"
};

const Layout = ({ children, title, noHeader }) => {
	const [open, setOpen] = useState(false);
	const [signOutModal, setSignOutModal] = useState(false);
	const [cookies] = useCookies([
		TOKEN_HOLDER,
		USER_NAME_HOLDER,
		USER_ROLE_HOLDER
	]);

	const { data, isLoading, error } = useApiGet(
		getStudentProfileUrl({ refCode: false }),
		{
			refetchOnWindowFocus: false,
			enabled: cookies[USER_ROLE_HOLDER] === "student"
		}
	);

	const menuItemsFromApi = useSelector((state) => state.menuItemsData);
	const { tokenExpirationDate, impersonatorUsername, isImpersonating } =
		useSelector((state) => state.impersonatorDetails);
	const { logout } = useAuthAction();

	const { [USER_ROLE_HOLDER]: userRole, [USER_NAME_HOLDER]: userName } =
		cookies;
	switch (userRole) {
		case "student":
			menuItems = menuItemsFromApi
				.map((item) => ({
					name: item,
					path: studentPaths[item.toLowerCase()]
				}))
				.filter((item) => item.path);
			break;
		case "applicant":
			menuItems = menuItemsFromApi
				.map((item) => ({
					name: item,
					path: applicantPaths[item.toLowerCase()]
				}))
				.filter((item) => item.path);
			break;
		default:
			menuItems = menuItemsFromApi
				.map((item) => ({
					name: item,
					path: staffPaths[item.toLowerCase()]
				}))
				.filter((item) => item.path);
			break;
	}

	if (isLoading) {
		return <PageLoader />;
	}

	if (error) {
		return "An error has occurred: " + error?.message;
	}

	return (
		<StudentProfileContext.Provider value={{ profileData: data?.data }}>
			<div className="app-container">
				<section className="res-header">
					{isImpersonating && (
						<Banner
							impersonator={impersonatorUsername}
							impersonated={userName}
							date={tokenExpirationDate}
							onEndSessionClick={() => logout()}
						/>
					)}
					<GlobalMenu
						title={title}
						openSide={() => setOpen(true)}
						setSignOutModal={setSignOutModal}
						userName={userName}
						userRole={userRole}
					/>
				</section>
				<section className={`${open ? "res-side" : "res-no-side"}`}>
					<div
						className="res-side-menu--background"
						onClick={() => setOpen(false)}
					></div>
					<SideMenu
						paths={menuItems}
						closeSide={() => setOpen(false)}
						setSignOutModal={setSignOutModal}
					/>
				</section>
				<div
					className={`children ${noHeader ? "no-header-tab" : ""}`}
					style={isImpersonating ? { paddingTop: "96px" } : {}}
					ref={parent}
				>
					{children}
				</div>
				<Logout
					isOpen={signOutModal}
					closeModal={() => setSignOutModal(false)}
				/>
			</div>
		</StudentProfileContext.Provider>
	);
};

export { Layout };
