import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "DirectEntryApplication";

export const directEntryLoadApplicationFormUrl = (jambRegNumber, sessionId) =>
	`${baseUrl}/load-application-form?regNumber=${jambRegNumber}&sessionId=${sessionId}`;

export const directEntryPersonalDetailsFormUrl = () =>
	`${baseUrl}/store-direct-entry-application-personal-details`;

export const directEntryProgrammeDetailsFormUrl = () =>
	`${baseUrl}/store-direct-entry-application-programme-details`;

export const directEntryEducationHistoryFormUrl = () =>
	`${baseUrl}/store-direct-entry-application-education-history-details`;

export const directEntryOLevelDetailsFormUrl = () =>
	`${baseUrl}/store-direct-entry-application-olevel-details`;

export const directEntryReferrerDetailsFormUrl = () =>
	`${baseUrl}/store-direct-entry-application-referral-details`;
export const getDirectApplicationReportUrl = (filter) =>
	`${baseUrl}/direct-entry-applications-report?${generateUrlParams(filter)}`;

export const downloadDirectApplicationReportUrl = (filter) =>
	`${baseUrl}/download-direct-entry-applications-report?${generateUrlParams(
		filter
	)}`;
