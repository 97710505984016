import { Route } from "react-router-dom";
import { Footer, GlobalMenu } from "../../ui_elements";

const HomeRouter = ({
	component,
	path,
	exact,
	purpose,
	title,
	user,
	...rest
}) => {
	let Component = component;

	return (
		<Route
			exact={exact}
			path={path}
			{...rest}
			render={(props) => {
				return (
					<div>
						<GlobalMenu isLanding={"unAuthenticated"} />
						<Component {...rest} {...props} />
						<Footer />
					</div>
				);
			}}
		/>
	);
};

export { HomeRouter };
