import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/sideLogo.png";
import christmasLogo from "../../assets/images/christmasLogo.png";
import ChristmasLogo from "../../assets/images/bannerLight.png";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";
import styles from "./auth_style.module.css";

export default function AuthPageWrapper({ children }) {
	const { push } = useHistory();
	return (
		<div className={`${styles.auth_container}`}>
			<div className={`${styles.auth_card} bg-white`}>
				{checkIfChristmasPeriod() ? (
					<div className={styles.christmasLightContainer}>
						<img
							alt="chrsitmas Light"
							className={styles.light}
							src={ChristmasLogo}
						/>
					</div>
				) : null}
				<div className={`mt-5 pt-2 ${styles.logo}`}>
					{checkIfChristmasPeriod() ? (
						<>
							<img src={christmasLogo} alt="CCU logo" />
						</>
					) : (
						<>
							<img
								src={
									checkIfChristmasPeriod()
										? christmasLogo
										: logo
								}
								alt="Login Logo"
								className={`${styles.loginLogo}`}
								onClick={() => push("/")}
							/>
						</>
					)}
				</div>
				{children}
			</div>
		</div>
	);
}
