import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "TransferApplication";

export const transferLoadApplicationFormUrl = (jambRegNumber, sessionId) =>
	`${baseUrl}/load-application-form?regNumber=${jambRegNumber}&sessionId=${sessionId}`;

export const transferPersonalDetailsFormUrl = () =>
	`${baseUrl}/store-transfer-application-personal-details`;

export const transferProgrammeDetailsFormUrl = () =>
	`${baseUrl}/store-transfer-application-programme-details`;

export const transferEducationHistoryFormUrl = () =>
	`${baseUrl}/store-transfer-application-education-history-details`;

export const transferOLevelDetailsFormUrl = () =>
	`${baseUrl}/store-transfer-application-olevel-details`;

export const transferReferrerDetailsFormUrl = () =>
	`${baseUrl}/store-transfer-application-referral-details`;

export const getTransferApplicationReportUrl = (filter) =>
	`${baseUrl}/transfer-applications-report?${generateUrlParams(filter)}`;

export const downloadTransferApplicationReportUrl = (filter) =>
	`${baseUrl}/download-transfer-applications-report?${generateUrlParams(
		filter
	)}`;
